/* eslint-disable @typescript-eslint/no-unsafe-argument */
import classNames from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { RTF } from "../elements";
import { CarouselContent } from "./Carousel";
import { I18Link } from "./Links";

export const GA_HillsAndValleysCarouselContent: React.FC<CarouselContent> = ({
  image,
  alt,
  title,
  tagline,
  messageRTF,
  link,
  highlightName,
  customclassname,
}) => {
  if (!image && !title) {
    return null;
  }
  if (typeof window === "undefined" || !window.document) {
    return;
  }
  const path = window.location.pathname;
  const inside = (
    <>
      {image && (
        <div className="aspect-w-1 aspect-h-1">
          <GatsbyImage
            style={{ position: "absolute" }}
            className={classNames(
              "row-start-1 row-span-1",
              "col-start-2 col-span-12",
              "lg:col-start-7 lg:col-span-7"
            )}
            imgStyle={{
              objectFit: "cover",
              objectPosition: "center",
            }}
            image={image}
            alt={alt || title}
          />
        </div>
      )}
      <h4 className="pt-20px h4">{title}</h4>
      {tagline && <h3 className="pt-20px h3">{tagline}</h3>}
      {messageRTF && <RTF content={messageRTF} className="pt-20px" />}
      {link && (
        <p
          className={classNames(
            highlightName && "text-dark-blue",
            customclassname,
            "block pt-40px read-more"
          )}
        >
          {/* Discover more */}
          {path.includes("/en/") ? "Discover more" : "Descubra mais"}
        </p>
      )}
    </>
  );

  const body = link ? <I18Link to={link}>{inside}</I18Link> : inside;

  if (!highlightName) {
    return body;
  }

  return (
    // <div className="border-8 border-dark-blue bg-dark-blue text-white-1">
    <div className={classNames("text-dark-blue", customclassname)}>
      <h5 className="pb-20px h5">{highlightName}</h5>
      {body}
    </div>
  );
};
