/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
import classNames from "classnames";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import { I18Link } from "./Links";

export interface SingleStory {
  title: string;
  link: string;
}

interface Props {
  title?: string;
  image?: IGatsbyImageData;
  alt?: string;
  stories: readonly SingleStory[];
  classvalue?: string;
  imagevalue?: string;
}

export const Stories: React.FC<Props> = ({
  title,
  image,
  alt,
  stories,
  classvalue,
  imagevalue,
}) => {
  if (!stories) {
    return null;
  }

  return (
    <section
      data-component="Stories"
      className="py-section w-full z-10"
      id="clientstories"
    >
      <div className="page-grid max-width">
        {title && (
          <h2
            className={classNames(
              classvalue
                ? classvalue
                : "row-start-1 col-start-3 col-span-10 text-center h7"
            )}
          >
            {title}
          </h2>
        )}
        {image && (
          <div
            className={classNames(
              "relative",
              "row-start-1 row-span-1",
              "lg:row-start-3 lg:row-span-1",
              "col-start-1 col-span-13",
              "lg:col-start-1 lg:col-span-7",
              "lg:pt-135px"
            )}
          >
            <div className="aspect-w-4 aspect-h-3">
              <GatsbyImage
                style={{ position: "absolute" }}
                imgStyle={{
                  objectFit: "cover",
                  objectPosition: "center",
                }}
                image={image}
                alt={alt || "Image"}
              />
            </div>
          </div>
        )}
        <div
          className={classNames(
            "relative mt-30px xl:mt-75px",
            "col-start-2 col-span-12",
            "lg:col-start-7 lg:col-span-8 lg:row-start-2 lg:row-span-2",
            "divide-y-2 divide-grey"
          )}
        >
          {stories.map((story, index) => {
            const { title, link, strong } = story;
            return (
              <a
                href={"/en" + link}
                className="bg-white-1 py-35px grid grid-gap grid-cols-8 items-center"
              >
                <I18Link
                  to={link}
                  // className="stories-tic-pattern bg-right-pattern bg-center bg-no-repeat bg-28px min-h-28px1"
                  className={classNames(
                    "bg-center bg-no-repeat bg-28px min-h-28px",
                    imagevalue ? imagevalue : " bg-right-pattern"
                  )}
                />
                <p className="p2 col-start-2 col-span-5">
                  <strong>{strong} </strong>
                  {title}
                </p>
              </a>
            );
          })}
        </div>
      </div>
    </section>
  );
};
